import Link from "next/link";
import { useContext, useEffect, useState } from "react";
import Logo from "./logo";
import styles from "./my-account-header.module.css";
import { TfiClose } from "react-icons/tfi";
import UserContext from "../../stores/user-context";
import ModalLogout from "../my-account-page/modal.logout";

const MyAccountHeader = ({ dark }) => {
  const userCtx = useContext(UserContext);
  const [heightPopup, setHeightPopup] = useState("0");
  const loginStatus = userCtx.user ? true : false;
  const [isLogin, setIsLogin] = useState(loginStatus);
  const [openModal, setOpenModal] = useState(false);
  const isDark = dark ?? false;

  useEffect(() => {
    setIsLogin(!!userCtx.user);
  }, [userCtx.user]);

  useEffect(() => {
    if (!openModal) {
      hideAccountPopup();
    }
  }, [openModal]);
  const showAccountPopup = () => {
    setHeightPopup("600px");
  };
  const hideAccountPopup = () => {
    setHeightPopup("0");
  };

  function showLogoutModal() {
    setOpenModal(true);
  }

  if (!isLogin) {
    return (
      <>
        <Link href="/login" prefetch={false}>
          <div className={styles.my_account}>Login</div>
        </Link>
      </>
    );
  }
  return (
    <>
      <div className={styles.my_account} onClick={showAccountPopup}>
        My Account
      </div>
      <div
        className={`${styles.canvas_account_position} ${
          isDark ? styles.dark : ""
        }`}
        style={{
          maxHeight: heightPopup,
        }}
      >
        <div className={styles.close_icon_popup} onClick={hideAccountPopup}>
          <TfiClose />
        </div>
        <div className={styles.canvas_account}>
          <div className={styles.account_logo}>
            <Logo />
          </div>
          <div className={styles.account}>
            <div className={styles.account_menu}>
              <div className={styles.account_title}>MY ACCOUNT</div>
              <div className={styles.account_menu_list}>
                <Link href="/account" prefetch={false}>
                  <div onClick={hideAccountPopup}>Personal Details</div>
                </Link>
                <Link href="/account#order" prefetch={false}>
                  <div onClick={hideAccountPopup}>Order History</div>
                </Link>
                <Link href="/account#point" prefetch={false}>
                  <div onClick={hideAccountPopup}>Points</div>
                </Link>
                {/* <Link href="" prefetch={false}>
                  <div>Wish List</div>
                </Link> */}
                <div className="link" onClick={showLogoutModal}>
                  <div>Logout</div>
                </div>
                <ModalLogout openModal={{ openModal, setOpenModal }} />
              </div>
            </div>
            <Link href="/track-order">
              <div onClick={hideAccountPopup}>Tracking Order</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyAccountHeader;
